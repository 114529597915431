/** @jsx jsx */
import {Box, jsx,Grid,Text,Heading,Link as ThLink} from 'theme-ui'
//import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'
import {GatsbyImage} from 'gatsby-plugin-image'
import { graphql,Link } from 'gatsby'
import {useState,useEffect} from 'react'







export default function Salon_2({data}){
    const [images,setImages]=useState([])
   
  

   useEffect(()=>{
   if(data){
    
    setImages(data.kaubfotos.nodes)
   }
  
    
   },[data])

        return(

            <Layout>
              <Box sx={{maxWidth:1000}}>
              <MySEO title="Salon in Kaub" description="Konzert in Kaub"  />
              <Heading as="h1" sx={{
                     fontWeight:500,
                     fontSize:[2,3],
                     mt:4
                 }}>
                     K.O.M.-Salon N°2 <br/> Ein Brückenschlag in vielerlei Hinsicht
                     </Heading>
                
                <Grid  gap={2} columns={[1,1,2]}>
                 
                 <Box sx={{margin:'0 4px'}}>
                    
                     <Text>Am Tag der Deutschen Einheit veranstaltete das Kulturnetz Oberes Mittelrheintal (K.O.M.) seinen 2. Salon in der Belle Étage des Blüchermuseums in Kaub.
</Text>


<Text py="2">
Dass man auch eher stille Orte äußerst vergnüglich und erbaulich beleben kann, bewies das Kulturnetz Oberes Mittelrheintal mit seinem 2. Salon im historischen Ambiente des aufwändig sanierten Blüchermuseums in Kaub.<br/>
 Handgemalte und restaurierte Tapeten mit romantischen Motiven, roter Teppich und festliche Kristalllüster boten das ideale Ambiente für ein Streichkonzert der besonderen Art rund um und moderiert von <strong>Prof. Annette Seyfried</strong>, Dozentin und Leiterin der „Junge Streicherakademie Mainz“ (<a sx={{color:'text'}} href="https://www.streicherakademie-mainz.de" rel="noopener noreferrer" target="_blank">www.streicherakademie-mainz.de</a>).<br/> Gemeinsam mit ihrer Schülerin <strong>Emma Mühlnickel</strong> an der Violine und ihrer ehemaligen Schülerin <strong>Clara Holdenried</strong>  an der Viola – seit 2020 Akademistin im Bayerischen Staatsorchester – ließ sie außergewöhnliche Werke von Mozart, Dvorak und Halvorsen erklingen.<br/> Gefördert durch die LAG Welterbe Oberes Mittelrheintal konnte auch diese Veranstaltung mit freiem Eintritt stattfinden und lockte einige Freunde der klassischen Musik in die musealen Räume.
</Text>
<Text py="2">
Sehr anrührend und ein glücklicher Zufall war auch die Anwesenheit des 2008 nach Colorado/USA ausgewanderten Ehepaares Conrad, die für kurze Zeit in ihrer alten Heimat, dem Oberen Mittelrheintal, weilten. Frau Conrads Großeltern lebten früher in dem Gebäude des jetzigen Blüchermuseums und sie selbst wusste einiges aus ihrer Kindheit zu erzählen. <br/>Gäste aus St. Goar, Bacharach und Boppard hatten die Überfahrt ans rechtsrheinische Ufer nicht gescheut. Besucher aus Wiesbaden und Mainz brachten ihre Kinder mit, die den Altersdurchschnitt der Salon-Gesellschaft erheblich zu verjüngen vermochten.
</Text> 
<Text py="2"> Katrin Gloggengießer und die Mitglieder des Kulturnetzes Oberes Mittelrheintal waren sehr zufrieden mit dem Verlauf dieses zweiten Salons und schmieden schon Pläne für einen dritten, der sich voraussichtlich noch dieses Jahr in der Adventszeit, diesmal linksrheinisch, realisieren lassen wird. 
</Text>
<Text py="2" mb="3">
Das schöne Blüchermuseum hat gezeigt, dass es ein hervorragender Ort für kleine Kammerkonzerte sein kann. Drücken wir alle die Daumen, dass dies der Anstoß für weitere, ähnliche Veranstaltungen war.<br/> Die Gäste waren generationenübergreifend begeistert. 

</Text> 

</Box>

    

           

                 {
                     images?
                     <Box> 
                        <GatsbyImage image={data.gruppen.childImageSharp.gatsbyImageData} alt="Abschlussfoto" />
                <Text sx={{fontSize:".9rem",lineHeight:1.1,marginTop:10,marginBottom:30}}>
                v.l.n.r.: Katrin Gloggengießer und Susanne Enderwitz, 1. und. 2. Vorsitzende des K.O.M., die Musikerinnen  Annette Seyfried, Clara Holdenried und Emma Mühlnickel, vorne rechts Frau Conrad, Nachfahrin der Besitzer des historischen Gebäudes, nebst musikalischem Nachwuchs.
                </Text>  
                     <Grid gap={2} columns={[1]}>
                         {
                    images.map((item,index)=> {
                        console.log(item)
                         return(
                        
                             <Box flex="1 1"  key={index}>
                                <GatsbyImage image={item.childImageSharp.gatsbyImageData} alt="Eingang Veranstaltungsort" /> 
                            </Box>
                         
                         )
                         })}
                         </Grid>
                          </Box> 
                         :
                         <p>Lade Daten ...</p>
                 }
             </Grid>
 
            </Box>

<hr/>
<Box sx={{
  
    '@media(maxWidth:768px)':{
      maxWidth: '400px',
    }
   }}>
    
   

   <Text variant="heading">Die Musikerinnen</Text>   
   <Grid columns={[1,2,2]} style={{marginBottom:30,padding:8,background:'rgba(122,122,122,.1'}}>
     <Box>
     <GatsbyImage image={data.annette.childImageSharp.gatsbyImageData} alt="Konzertimpression" />
      <p sx={{color:'text',lineHeight:'1', mt:2 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Annette Seyfried</p>
     </Box>
     <Box>
     <Text mb="20px">
Annette Seyfried studierte an der Musikhochschule Saarbrücken und an der Robert Schumann Hochschule in Düsseldorf sowie in der Solistenklasse an der Guildhall School of Music & Drama in London. Seit 1997 lehrt sie neben ihrer Konzerttätigkeit als Kammermusikerin, Konzertmeisterin und Orchestermusikerin mit einer Honorarprofessur an der Hochschule für Musik Mainz und leitet die Junge Streicherakademie, die viele herausragende Talente mit zahlreichen Wettbewerbserfolge ausbildet.
</Text>  
<Text>
  <a sx={{color:'text'}} rel="noopener noreferrer" target="_blank" href="https://www.streicherakademie-mainz.de">&rarr; Streicherakademie Mainz</a>
</Text>
     </Box>
    </Grid>
   <Grid columns={[1,2,2]} style={{marginBottom:30,padding:8,background:'rgba(122,122,122,.1'}}>
     <Box>

     <GatsbyImage image={data.emma.childImageSharp.gatsbyImageData} alt="Salonbild Blüchermuseum" />
      <p sx={{color:"text",lineHeight:'1', mt:2,fontWeight:300,fontStyle:'italic', fontSize:0}}>Emma Muehlnickel </p> 
     </Box> 
     <Box>
     <Text>
       Emma Mühlnickel (*2003) ist seit 2008 Schülerin der Jungen Streicherakademie Mainz bei Prof. Annette Seyfried. Sie war bereits dreimal 1. Bundespreisträgerin im Wettbewerb Jugend musiziert. Seit 2019 ist sie Konzertmeisterin des Landesjugendorchesters Rheinland-Pfalz. Sie trat mehrfach als Solistin mit Orchester auf, u.a. mit dem hr-Sinfonieorchester bei der Schultour 2019.
    </Text>
   
     </Box>
   </Grid> 
   <Grid columns={[1,2,2]}style={{marginBottom:30,padding:8,background:'rgba(122,122,122,.1'}}>
     <Box>
     <GatsbyImage image={data.clara.childImageSharp.gatsbyImageData} alt="Salonbild Blüchermuseum" />
      <p sx={{color:"text",lineHeight:'1', mt:2,fontWeight:300,fontStyle:'italic', fontSize:0}}>Clara Holdenried</p> 
  
     </Box>
     <Box>
     <Text>Clara Holdenried (*1995), ehemalige Schülerin von Prof. Annette Seyfried, absolvierte ihr Bachelorstudium bei Prof. Roland Glassl und studiert nun als Masterstudentin bei Prof. Hariolf Schlichtig in München. 2017 war sie Stipendiatin der Zukunftsinitiative Rheinland-Pfalz, seit 2019/20 ist sie Stipendiatin der Villa Musica. Seit März 2020 spielt sie als Akademistin im Bayerischen Staatsorchester.

     </Text>
    
     </Box>
     <Link to ="/salons"><ThLink>&rarr; Salons</ThLink></Link>
    </Grid>
   </Box>


              </Layout>
          )
  

}




export const salon2bQuery = graphql`
    query {
   
        kaubfotos: allFile (sort:{fields:relativePath},filter: {sourceInstanceName: {eq: "kaub"}}){
          nodes {
           relativePath
           childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
             
            )
          }
       
         }
         }
         gruppen:file(relativePath: { eq: "salons/gruppe.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                
              )
            }
          }
          usa:file(relativePath: { eq: "salons/5.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                
              )
            }
          }
          emma:file(relativePath: { eq: "salons/emmamuehlnickel.jpg" }) {
            childImageSharp {
            
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
              
              )
            }
          }
    
          clara:file(relativePath: { eq: "salons/holdenried.jpg" }) {
            childImageSharp {
            
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
              
              )
            }
          } 
          annette:file(relativePath: { eq: "salons/annetteseyfried.jpg" }) {
            childImageSharp {
            
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
              
              )
            }
          }

    }
    `